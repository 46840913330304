<template>
  <div>
    <div v-if="this.$route.name === 'SalesReport'">
      <SalesReport />
    </div>
    <router-view :key="$route.fullPath" />
  </div>
</template>
<script>
import SalesReport from "@/views/companyApp/salesReport/SalesReport.vue";

export default {
  name: 'SalesReportParent',
  components: {
    SalesReport
  }
}
</script>
